import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable, catchError, switchMap, tap } from 'rxjs';
import { MSALScopes } from '../enums/msal.enum';
import { LocalStorage } from '../enums/store-constants.enum';
import { AuthService } from '../services/auth.service';
import { LoaderActions } from '../store/loader/loader.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private msalService: MsalService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let isMSRequest = request.url.includes(
      environment.microsoftSSO.graphEndpoint,
    );

    this.store.dispatch(LoaderActions.setVisibility({ visible: true }));

    if (isMSRequest) {
      return this.msalService
        .acquireTokenSilent({
          authority: `${environment.microsoftSSO.cloudInstanceId}/common`,
          redirectUri: environment.microsoftSSO.redirectURI,
          scopes: MSALScopes,
        })
        .pipe(
          switchMap((msResponse) => {
            return next.handle(
              request.clone({
                headers: request.headers.set(
                  'Authorization',
                  `Bearer ${msResponse.accessToken}`,
                ),
              }),
            );
          }),
        );
    }

    return next
      .handle(
        request.clone({
          headers: request.headers.set(
            'Authorization',
            `Bearer ${localStorage.getItem(LocalStorage.jwtToken)}`,
          ),
          // .set('pmm3k-org-id', AuthService.getOrganization()?._id || '')
          // .set('pmm3k-user-id', AuthService.getUser()?._id || ''),
        }),
      )
      .pipe(
        tap((response: any) => {
          if (response.type === 4) {
            this.store.dispatch(
              LoaderActions.setVisibility({ visible: false }),
            );

            if (response.body?.message) {
              this.snackBar.open(response.body.message);
            }
          }
        }),
        catchError((response) => {
          this.store.dispatch(LoaderActions.setVisibility({ visible: false }));

          console.error(response, response.error);

          if (response.type === 'error') {
            this.snackBar.open('Something went wrong');
          }

          if (response.error?.name === 'TokenExpiredError') {
            this.snackBar.open('Session expired');

            this.authService.logOut();

            throw response;
          } else if (response.error?.name === 'JsonWebTokenError') {
            this.snackBar.open('Not authorized');

            this.authService.logOut();

            throw response;
          }

          if (response.type === 'error') {
            this.snackBar.open('Internal server error');

            throw response;
          }

          this.snackBar.open(
            response.error?.message ||
              response.error?.error?.message ||
              'Something went wrong',
          );

          if (response.hasOwnProperty('error')) {
            throw response;
          }

          return [] as any[];
        }),
      );
  }
}
